<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!-- <TitleModule :title="'修改错峰信息'"></TitleModule> -->
      <!--搜索条件区域-->
      <div class="searchWrapper">
        <el-form
          label-position="left"
          label-width="200px"
          :model="formInline"
          style="width:100%;float: left;margin-left:0"
        >
          <span class="messTitle">订单信息</span>
          <el-form-item
            label="订单状态:"
            prop="operationManagerName"
            style="padding-left:100px;margin-bottom:0"
          >
            <span>{{formInline.orderState}}</span>
          </el-form-item>
          <el-form-item :label="$t('searchModule.plate_number')" style="width: 320px;padding-left:100px;margin-bottom:0">
            <el-select v-model="formInline.plateNumber" @change="plateNumberChange">
              <el-option
                :label="value.plateNumber"
                :value="value.carId"
                v-for="value in parkData"
                :key="value.plateNumber"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="支付时间:" prop="sex" style="padding-left:100px;margin-bottom:0">
            <span>{{formInline.appointmentStartTime}}</span>
          </el-form-item>
          <el-form-item label="手机号:" prop style="padding-left:100px;margin-bottom:0">
            <span>{{formInline.mobile}}</span>
          </el-form-item>
          <el-form-item label="原价:" prop="sex" style="padding-left:100px;margin-bottom:0">
            <span>{{formInline.appointmentEndTime}}</span>
          </el-form-item>
          <el-form-item label="优惠金额:" prop="sex" style="padding-left:100px;margin-bottom:0">
            <span>{{formInline.appointmentEndTime}}</span>
          </el-form-item>
          <el-form-item label="订单金额:" prop="sex" style="padding-left:100px;margin-bottom:0">
            <span>{{formInline.appointmentEndTime}}</span>
          </el-form-item>
          <el-form-item label="支付方式:" prop="sex" style="padding-left:100px;margin-bottom:0">
            <span>{{formInline.appointmentEndTime}}</span>
          </el-form-item>
          <span class="messTitle">商品信息</span>
          <el-form-item label="停车场名称:" prop="sex" style="padding-left:100px;margin-bottom:0">
            <span>{{formInline.parkName}}</span>
          </el-form-item>
          <el-form-item label="错峰明细:" prop="account" style="padding-left:100px;margin-bottom:0">
            <span>{{formInline.payTime}}</span>
          </el-form-item>
        </el-form>
        <div style="text-align: left;  margin-left: 8.5%; margin-top: 40px;">
          <el-button type="primary" @click="submitData" style="width: 88px;">提交</el-button>
          <el-button type @click="$router.go(-1)" style="width: 88px;">取消</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {} from "@/common/js/public.js";
// import TitleModule from '@/components/titleModule/titleModule'
export default {
  name: "staggeredOrderAE",
  data() {
    return {
      isEdit: false,
      treeList: [],
      oldName: "",
      resourceList: [],
      formInline: {},
      parkData: [],
    };
  },
  methods: {
    queryDetail() {
      this.$axios
        .get(
          "/acb/2.0/parkAppointmentOrder/detail/" +
            this.$route.query.parkAppointmentOrderId
        )
        .then((res) => {
          res.value.payMoney = (res.value.payMoney / 100).toFixed(2);
          res.value.refundMoney = (res.value.refundMoney / 100).toFixed(2);
          this.formInline = res.value;
          this.getPlateNumber();
        });
    },
    plateNumberChange(item) {
      this.formInline.carId = item;
    },
    getPlateNumber() {
      this.$axios
        .get("/acb/2.0/memberInfo/listMemberCar", {
          data: {
            memberId: this.formInline.memberId,
          },
        })
        .then((res) => {
          this.parkData = res.value;
        });
    },
    submitData() {
      let opt = {
        method: "post",
        url: "/acb/2.0/parkAppointmentOrder/modify",
        data: {
          parkAppointmentOrderId: this.formInline.appointmentOrderId,
          memberId: this.formInline.memberId,
          carId: this.formInline.carId,
        },
      };
      this.$axios(opt).then((res) => {
        if (res.state == 0) {
          this.$router.go(-1);
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
  },
  // components: {
  //   TitleModule
  // },
  created() {},
  mounted() {
    this.queryDetail();
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
}

.content {
  background: #FFFFFF;
  overflow: hidden;
  border: 1px solid #C0CCDA;
  border-radius: 4px;
  padding: 20px;
}

.messTitle {
  display: block;
  height: 40px;
  background: rgba(178, 178, 178, 0.6);
  text-align: left;
  width: 100%;
  line-height: 40px;
  text-indent: 40px;
}
</style>
